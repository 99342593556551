var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "foot",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-wrap btn-wrap--lg"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          on: {
            "click": function ($event) {
              return _vm.$emit('save');
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.attrs, {
          loading: _vm.loading,
          disabled: _vm.disabled
        }), false), [_vm._v("신청하기")])], 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }